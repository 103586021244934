<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Lịch sử gắn giáo viên cho ticket
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <div>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Trạng thái:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    class=""
                    :items="status_tags"
                    item-text="label"
                    item-value="value"
                    v-model="status_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  >
                  </v-autocomplete>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Kĩ năng:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    :items="skill_tags"
                    item-text="name"
                    item-value="name"
                    v-model="skill_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Lớp:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    :items="class_tags"
                    item-text="name"
                    item-value="name"
                    v-model="class_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" class="m-0 p-0"></v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Marker cũ:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    :items="marker_tags"
                    item-text="name"
                    item-value="id"
                    v-model="old_marker_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Marker mới:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    :items="marker_tags"
                    item-text="name"
                    item-value="id"
                    v-model="new_marker_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Người thay đổi:</label
                >
                <div class="col-8 p-0">
                  <v-autocomplete
                    :items="marker_tags"
                    item-text="name"
                    item-value="id"
                    v-model="modified_user_input"
                    filled
                    color="blue-grey lighten-2"
                    dense
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" class="p-0 m-0"></v-col>
              <v-col cols="3" class="d-flex align-items-center p-0 m-0 row">
                <label class="text-body-1 font-weight-bold col-3 pr-0 pt-0"
                  >Lọc theo ngày:</label
                >
                <div class="col-8 p-0">
                  <v-radio-group
                    class="mt-0 pt-0"
                    v-model="is_filter_by_marking_date"
                    row
                  >
                    <v-radio label="Học sinh nộp" :value="0"></v-radio>
                    <v-radio label="Giáo viên đã chấm" :value="1"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>

              <v-col cols="12" class="m-0 p-0"></v-col>
              <v-col cols="5" class="d-flex align-items-center pt-0 pb-0 row">
                <label class="text-body-1 font-weight-bold col-2"
                  >Khoảng thời gian:</label
                >
                <div class="col-9 d-flex p-0">
                  <div class="d-flex">
                    <label class="text-body-1 font-weight-medium mb-6 pt-4"
                      >Từ:</label
                    >
                    <date-picker
                      style=""
                      class="ml-2 mt-3"
                      v-model="date_start_input"
                      value-type="format"
                      format="YYYY-MM-DD"
                    />
                  </div>
                  <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
                  <div class="d-flex">
                    <label class="text-body-1 font-weight-medium mb-6 pt-4"
                      >Đến:</label
                    >
                    <date-picker
                      style=""
                      class="ml-2 mt-3"
                      v-model="date_end_input"
                      value-type="format"
                      format="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </v-col>
              <v-col cols="9" class="p-0">
                <div class="text-center">
                  <v-btn
                    color="cyan"
                    class="ma-2 font-weight-bold"
                    outlined
                    large
                    @click="getAllMarkerHistory()"
                  >
                    <v-icon left>mdi-magnify</v-icon> Tìm kiếm
                  </v-btn>
                  <v-btn
                    color="red"
                    class="ma-2 font-weight-bold"
                    outlined
                    large
                    @click="btnResetFilterData"
                  >
                    <v-icon left>mdi-backup-restore</v-icon> Hủy lọc
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="m-0 p-0"></v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12">
            <p class="text-body-1"><b>Tổng</b>: {{ data_pagination.total }}</p>
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">Ticket No</th>
                    <th scope="col">SUBMISSION INFORMATION</th>
                    <th scope="col">LESSON / TEST</th>
                    <th scope="col">SUBMIT TIME</th>
                    <th scope="col">MARKER</th>
                    <th scope="col">Status</th>
                    <th scope="col">marker_history</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(ticket, i) in data_marker_history">
                    <tr :key="i">
                      <td scope="row">
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >#{{ ticket.id }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ ticket.student_name }} (ID: #{{
                            ticket.student_id
                          }})</span
                        >
                        <span
                          class="text-body-1 text-muted font-weight-bold d-block"
                        >
                          Course: {{ ticket.course_name.vi }}
                        </span>
                        <span
                          class="text-body-1 text-dark-50 font-weight-bold d-block"
                          v-if="ticket.class_name"
                        >
                          Class: {{ ticket.class_name }}
                        </span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ ticket.title }}</span
                        >
                        <span class="text-body-1 font-weight-bold">
                          Skill: {{ ticket.skill_name }}
                        </span>
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          >{{ ticket.time }}</span
                        >
                      </td>
                      <td>
                        <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ ticket.marker_name }}
                        </span>
                      </td>
                      <td>
                        <span
                          v-if="ticket.status === 1"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
                        >
                          Unmarked
                        </span>
                        <span
                          v-if="ticket.status === 2"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                        >
                          Marking
                        </span>
                        <span
                          v-if="ticket.status === 3"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-success"
                        >
                          Marked
                        </span>
                      </td>
                      <td>
                        <div v-if="ticket.marker_history">
                          <span
                            class="text-dark-75 d-block font-size-lg"
                            v-if="ticket.marker_history.old_marker"
                          >
                            <b>Giáo viên cũ</b>:
                            {{ ticket.marker_history.old_marker }}
                          </span>
                          <span class="text-dark-75 d-block font-size-lg">
                            <b>Người gắn ticket</b>:
                            {{ ticket.marker_history.modified_user }}
                          </span>
                          <span class="text-dark-75 d-block font-size-lg">
                            <b>Thời gian</b>:
                            {{ ticket.marker_history.created_at }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!data_marker_history.length">
                    <tr>
                      <td colspan="9" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "MarkerHistory",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      data_marker_history: [],
      data_pagination: {
        total: 0,
        current_page: 1,
        total_pages: 1,
      },
      date_start_input: null,
      date_end_input: null,
      status_tags: [
        { label: "Mới", value: 1 },
        { label: "Đang chấm", value: 2 },
        { label: "Đã chấm", value: 3 },
      ],
      status_input: null,
      skill_tags: [],
      skill_input: null,
      class_tags: [],
      class_input: null,
      is_filter_by_marking_date: 0,
      marker_tags: [],
      old_marker_input: null,
      modified_user_input: null,
      new_marker_input: null,
    };
  },
  watch: {
    "data_pagination.current_page": function (page) {
      this.getAllMarkerHistory(page);
    },
  },
  created() {
    this.getAllSkill();
    this.getAllClass();
    this.getListMarker();
    this.getAllMarkerHistory();
  },
  methods: {
    async getAllMarkerHistory(page = 1) {
      let vm = this;
      try {
        vm.is_call_api = true;
        let url = "prep-app/marking-ticket/list-marker-history?page=" + page;
        url = url + "&is_filter_marking_date=" + this.is_filter_by_marking_date;
        let checkUrl = this.checkUrlMarkingTicket(url);
        let res = await ApiService.get(checkUrl);
        if (res.status === 200) {
          vm.data_marker_history = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    checkUrlMarkingTicket(url) {
      if (
        !(this.old_marker_input === null || this.old_marker_input === undefined)
      ) {
        url = url + "&old_marker=" + this.old_marker_input;
      }
      if (
        !(
          this.modified_user_input === null ||
          this.modified_user_input === undefined
        )
      ) {
        url = url + "&modified_user=" + this.modified_user_input;
      }
      if (
        !(this.new_marker_input === null || this.new_marker_input === undefined)
      ) {
        url = url + "&new_marker=" + this.new_marker_input;
      }
      if (
        !(this.date_start_input === null || this.date_start_input === undefined)
      ) {
        url = url + "&date_start=" + this.date_start_input;
      }
      if (
        !(this.date_end_input === null || this.date_end_input === undefined)
      ) {
        url = url + "&date_end=" + this.date_end_input;
      }
      if (
        !(
          this.skill_input === "" ||
          this.skill_input === undefined ||
          this.skill_input == null
        )
      ) {
        url = url + "&skill_name=" + this.skill_input;
      }
      if (
        !(
          this.class_input === "" ||
          this.class_input === undefined ||
          this.class_input == null
        )
      ) {
        url = url + "&class_name=" + this.class_input;
      }
      if (
        !(
          this.status_input === "" ||
          this.status_input === undefined ||
          this.status_input == null
        )
      ) {
        url = url + "&status=" + this.status_input;
      }
      return url;
    },
    async getAllSkill() {
      let seft = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          seft.skill_tags = response.data;
        }
      });
    },
    async getAllClass() {
      let seft = this;
      await ApiService.get("prep-app/class").then(function (response) {
        if (response.status === 200) {
          seft.class_tags = response.data;
        }
      });
    },
    getListMarker() {
      let self = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          self.marker_tags = response.data;
        }
      });
    },
    btnResetFilterData() {
      this.date_start_input = null;
      this.date_end_input = null;
      this.skill_search_input = null;
      this.class_search_input = null;
      this.old_marker_input = null;
      this.modified_user_input = null;
      this.new_marker_input = null;
      this.getAllMarkerHistory();
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
